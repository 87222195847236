html {
  @apply antialiased font-normal font-body text-base;
  text-rendering: optimizeLegibility;
}

body {
  @apply bg-gray-dark text-white;
}

.wf-loading {
  @apply invisible;
}

.rich-text {
  p,
  ol,
  ul {
    margin-top: 1em;
  }

  a {
    @apply text-white transition-colors duration-200;

    &:hover {
      @apply underline text-gray-base;
    }
  }

  h2 {
    @apply font-display leading-tight uppercase text-2xl font-bold text-white mb-4;
  }

  p + h2 {
    @apply mt-12;
  }

  h3 {
    @apply font-display leading-tight uppercase text-xl font-bold text-white mt-12;

    + p {
      @apply mt-1;
    }
  }
}

.page {
  @apply min-h-screen;
  background-image: url('https://s3-us-east-2.amazonaws.com/rhm-assets/u/2020/02/black-hill-bg-1.jpg');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.text-shadow {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
